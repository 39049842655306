<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="商户名称">
        <el-input
          v-model="searchForm.merchantName"
          size="mini"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column
        prop="no"
        label="序号"
        width="50"
        type="index"
      ></el-table-column>
      <el-table-column prop="merchantName" label="商户名称"></el-table-column>
      <el-table-column prop="merchantId" label="商户编码"></el-table-column>
      <el-table-column prop="balance" label="余额">
        <template slot-scope="scope">
          <span>{{scope.row.balance / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lockBalance" label="锁定余额">
        <template slot-scope="scope">
          <span>{{scope.row.lockBalance / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rechargeMatch" label="代收分流自动上浮">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.rechargeMatch"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#e6e6e6"
            @change="handleRecharMatchChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="inMatch" label="代收分流">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.inMatch"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#e6e6e6"
            @change="handleInMatchChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="outMatch" label="代付分流">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.outMatch"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#e6e6e6"
            @change="handleOutMatchChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="inFeeRate" label="代收费率">
        <template slot-scope="scope">
          <span>{{ scope.row.inFeeRate * 100 }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="outFeeRate" label="代付费率">
        <template slot-scope="scope">
          <span>{{ scope.row.outFeeRate * 100 }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="outFeeFix" label="固定费用">
        <template slot-scope="scope">
          <span>{{ scope.row.outFeeFix / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{ statusDesc(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{ dateTransform(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column label="操作" width="100">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
    <el-dialog
      title="修改商户配置"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="editDialogForm"
    >
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="120px"
      >
        <el-form-item label="商户名称" prop="merchantName">
          <el-col :span="12">
            <el-input v-model="editForm.merchantName" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="放款渠道" prop="lendingPayChannel">
          <el-col :span="12">
            <el-select
              v-model="editForm.lendingPayChannel"
              @change="getLendingPayAccountList"
            >
              <el-option
                v-for="item in payChannelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="放款支付账号" prop="lendingPayAccountId">
          <el-col :span="12">
            <el-select v-model="editForm.lendingPayAccountId" clearable>
              <el-option
                v-for="item in lendingPayAccountList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="还款渠道" prop="repaymentPayChannel">
          <el-col :span="12">
            <el-select
              v-model="editForm.repaymentPayChannel"
              @change="getRepaymentPayAccountList"
            >
              <el-option
                v-for="item in payChannelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="还款支付账号" prop="repaymentPayAccountId">
          <el-col :span="12">
            <el-select v-model="editForm.repaymentPayAccountId" clearable>
              <el-option
                v-for="item in repaymentPayAccountList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogForm = false">取消</el-button>
        <el-button type="primary" @click="editSubmit" :loading="editLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from "../../api/routerConfig/merchantConfig";
import DataUtil from "../../utils/config/dataUtil";
import Enum from "../../utils/Enum/Enum";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,
      payChannelList: Enum.payChannelList,
      loading: false,
      editDialogForm: false,
      editLoading: false,
      tableData: [],
      searchForm: {
        merchantName: "",
        lendingPayChannel: "",
        lendingAccountNo: "",
        repaymentPayChannel: "",
        repaymentAccountNo: "",
      },

      rules: {
        merchantName: [
          {
            required: true,
            message: "请填写商户名称",
            trigger: "blue",
          },
        ],
        lendingPayChannel: [
          {
            required: true,
            message: "请填写放款渠道",
            trigger: "blue",
          },
        ],
        lendingPayAccountId: [
          {
            required: true,
            message: "请选择放款支付账号",
            trigger: "change",
          },
        ],
        repaymentPayChannel: [
          {
            required: true,
            message: "请填写还款渠道",
            trigger: "blue",
          },
        ],
        repaymentPayAccountId: [
          {
            required: true,
            message: "请选择还款款支付账号",
            trigger: "change",
          },
        ],
      },
      addForm: {
        merchantName: "",
        lendingPayChannel: "",
        lendingPayAccountId: "",
        repaymentPayChannel: "",
        repaymentPayAccountId: "",
      },
      editForm: {
        id: "",
        nmerchantName: "",
        lendingPayChannel: "",
        lendingPayAccountId: "",
        repaymentPayChannel: "",
        repaymentPayAccountId: "",
      },
      lendingPayAccountList: [],
      repaymentPayAccountList: [],
    };
  },
  methods: {
    dateTransform(date) {
      return DataUtil.dateTransform(date);
    },
    statusDesc(status) {
      if (status == 1) {
        return "停用";
      }
      return "启用";
    },
    matchDesc(matchType) {
      if (matchType == 1) {
        return "权重";
      }
      return "自定义";
    },
    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },
    getLendingPayAccountList(val) {
      let _data = {};
      let content = {};
      content.type = 0;
      content.payChannel = val;
      _data.content = content;
      api.getPayAccountList(_data).then((res) => {
        if (res.code == "0000") {
          this.lendingPayAccountList = res.data.rows;
        }
      });
    },
    handleInMatchChange(row) {
      api
        .saveInMatch({
          content: { inMatch: row.inMatch, id: row.id },
        })
        .then((res) => {
          if (res.code === "0000") {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(row.message);
          }
        });
    },
    handleRecharMatchChange(row) {
      console.log(row);
      api
        .rechargeMatch({
          content: { inMatch: row.rechargeMatch, id: row.id },
        })
        .then((res) => {
          if (res.code === "0000") {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(row.message);
          }
        });
    },
    handleOutMatchChange(row) {
      api
        .saveOutMatch({
          content: { outMatch: row.outMatch, id: row.id },
        })
        .then((res) => {
          if (res.code === "0000") {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(row.message);
          }
        });
    },
    getRepaymentPayAccountList(val) {
      let _data = {};
      let content = {};
      content.type = 1;
      content.payChannel = val;
      _data.content = content;
      api.getPayAccountList(_data).then((res) => {
        if (res.code == "0000") {
          this.repaymentPayAccountList = res.data.rows;
        }
      });
    },
    bindGrid() {
      this.loading = true;
      let _data = {};
      let content = Object.assign({}, this.searchForm);
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      _data.content = content;
      api.merchantConfigList(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.loading = false;
          this.tableData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    edit(row) {
      // this.getRepaymentPayAccountList(row.repaymentPayChannel);
      // this.getLendingPayAccountList(row.lendingPayChannel);
      this.editForm.id = row.id;
      this.editForm.merchantName = row.merchantName;

      // this.editForm.lendingPayChannel = row.lendingPayChannel;
      // this.editForm.lendingPayAccountId = row.lendingPayAccountId;
      // this.editForm.repaymentPayChannel = row.repaymentPayChannel;
      // this.editForm.repaymentPayAccountId = row.repaymentPayAccountId;
      this.editDialogForm = true;
    },
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          let _data = {};
          let content = Object.assign({}, this.editForm);
          _data.content = content;
          api.editMerchantConfig(_data).then((res) => {
            this.editLoading = false;
            if (res.code == "0000") {
              this.editDialogForm = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
  },
  mounted: function () {
    this.bindGrid();
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
</style>
